import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = props => (
  <Layout>
    <SEO title="Lou Guarini Plumbing & Heating" />

    <div class="container">
      <div
        id="carouselExampleCaptions"
        class="carousel slide d-none d-sm-block"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleCaptions"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <Img
              fluid={props.data.bathroom1.childImageSharp.fluid}
              class="d-block w-100"
              alt="jersey city bathroom"
            />
            <div class="carousel-caption">
              <blockquote class="blockquote text-center">
                <p>
                  I just wanted to let you know how much I appreciate the fact
                  that they came when they said they would, even calling to let
                  me know they were on their way.
                </p>
                <footer class="blockquote-footer">Cathy B.</footer>
              </blockquote>
            </div>
          </div>
          <div class="carousel-item">
            <Img
              fluid={props.data.bathroom2.childImageSharp.fluid}
              class="d-block w-100"
              alt="jersey city bathroom"
            />
            <div class="carousel-caption">
              <blockquote class="blockquote text-center">
                <p>
                  Thank you so much for your prompt services, fair prices and
                  great customer service you and your plumbers gave us. If
                  anyone is ever looking for a plumber I am sending them your
                  way!
                </p>
                <footer class="blockquote-footer">Chrissy M.</footer>
              </blockquote>
            </div>
          </div>
          <div class="carousel-item">
            <Img
              fluid={props.data.bathroom4.childImageSharp.fluid}
              class="d-block w-100"
              alt="jersey city bathroom"
            />
            <div class="carousel-caption">
              <blockquote class="blockquote text-center">
                <p>
                  It’s unfortunately rare to find a business that’s so
                  responsive to its customers. We’ll definitely recommend you to
                  our friends.
                </p>
                <footer class="blockquote-footer">Angie & Twan</footer>
              </blockquote>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleCaptions"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleCaptions"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>

      <div class="row text-center" id="service-cards">
        <div class="col-md">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Heating</h5>
              <p class="card-text">
                Servicing your heating system annually can help save you money
                and keep you safe.
              </p>
              <Link to="/services/#heating" class="btn btn-primary">
                Heating Tips
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Water Heaters</h5>
              <p class="card-text">
                The average water heater will last around 10 years before it
                need to be replaced.
              </p>
              <Link to="/services/#water-heater" class="btn btn-primary">
                Water Heater Tips
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Bathrooms</h5>
              <p class="card-text">
                Leaks in your bathroom can add $100's a month to your water
                bill.
              </p>
              <Link to="/services/#bathroom" class="btn btn-primary">
                Bathroom Tips
              </Link>
            </div>
          </div>
        </div>
      </div>

      <h1>Who We Are...</h1>
      <div class="d-block d-sm-none">
        <Img
          fluid={props.data.bathroom1.childImageSharp.fluid}
          alt="jersey city bathroom"
        />
      </div>
      <p>
        Lou Guarini Plumbing and Heating has been serving Jersey City for four
        generations. We specialize in{" "}
        <Link to="/services/#heating">heating</Link>,{" "}
        <Link to="/services/#bathroom">bathrooms</Link>,{" "}
        <Link to="/services/#kitchen">kitchens</Link> and{" "}
        <Link to="/services/#water-heater">water heaters</Link>. Our family
        owned and operated company provides our customers with knowledge,
        experience and dedication that guarantees quality work. When you deal
        with our company you are dealing with the owner directly.
      </p>

      <p>
        Working in Jersey City provides us with the unique opportunity to work
        on homes that were built over 100 years ago along with new construction
        . When it comes to the historic homes, there are many issues that your
        average handy man might not be ready to tackle. We have the experience
        you need when it comes to taking care of your Downtown brownstone or
        your West Side victorian.
      </p>
      <h1>Our Promise to You...</h1>
      <div class="row">
        <div class="col-sm-5">
          <Img
            fluid={props.data.bathroom4.childImageSharp.fluid}
            alt="jersey city bathroom"
          />
        </div>

        <div class="col-sm-7">
          <p>
            We know you have a lot to deal with in your daily life. The last
            thing you need is the stress of shopping around for a reliable
            plumber. We are here to ease your frustration with any plumbing
            issues that may come up in your home or business. Whether it's
            fixing a leaky faucet or helping to get your{" "}
            <Link to="/services/#heating">heat</Link> going again, you can count
            on us.
          </p>
        </div>
        <div class="col-sm-7">
          <p>
            You can take comfort in working with a company that is licensed,
            insured and cares about your home as much as you do. We take pride
            in getting the job done right the first time and stand by our work
            even after the job is complete. Contact us today if you need help
            with any of the plumbing issues you might be having.
          </p>
        </div>
        <div class="col-sm-5">
          <Img
            fluid={props.data.bathroom2.childImageSharp.fluid}
            alt="jersey city bathroom"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const sliderImage = graphql`
  fragment sliderImage on File {
    childImageSharp {
      fluid(maxWidth: 800, maxHeight: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    bathroom1: file(relativePath: { eq: "bathroom1.jpg" }) {
      ...sliderImage
    }
    bathroom2: file(relativePath: { eq: "bathroom2.jpg" }) {
      ...sliderImage
    }
    bathroom3: file(relativePath: { eq: "bathroom3.jpg" }) {
      ...sliderImage
    }
    bathroom4: file(relativePath: { eq: "bathroom4.jpg" }) {
      ...sliderImage
    }
  }
`
